.site-menu{
	.localnav{
		margin: 0 0 64px;
		@media screen and (max-width: $phone){
			margin: 0 0 32px;	
		}
		ul{
			@include flex();
			justify-content: flex-start;
			margin: 0 0 0 -8px;
			@media screen and (max-width: $phone){
				margin: 0 0 0 -2px;
			}
			li{
				width: 25%;
				padding: 0 0 0 8px;
				margin: 0 0 8px;
				@media screen and (max-width: $phone){
					width: 50%;
					padding: 0 0 0 2px;
					margin: 2px 0 0;
				}
				a{
					display: block;
					background: $red;
					border: $red 1px solid;
					color: #FFF;
					font-weight: 700;
					font-size: 15px;
					padding: 12px 15px;
					text-align: center;
					border-radius: 2px;
					@media screen and (max-width: $phone){
						border-radius: 0;	
					}
					&:hover{
						background: lighten($red,8%);
					}
				}
			}
		}
	}
	
	.menubox{
		margin: 0 0 64px;
		padding: 0 0 64px;
		border-bottom: rgba(#FFF,.2) 1px solid;
		position: relative;
		@media screen and (max-width: $phone){
			margin: 0 0 32px;
			padding: 0 0 32px;
		}
		&:last-of-type{
			margin: 0;
			padding: 0;
			border: none;
		}
		&__pp{
			position: absolute;
			top: 0;
			right: 0;
			border: $red 1px solid;
			background: $red;
			color: #FFF;
			padding: 6px 40px;
			font-weight: 700;
			@media screen and (max-width:$tab){
				position: relative;
				padding: 10px 0;
				display: block;
				text-align: center;
				margin: 0 0 20px;
				font-size: 16px;
			}
			i{
				display: inline-block;
				margin: 0 8px 0 0;
			}
			&:hover{
				background: none;
				color: $red;
			}
		}
		&__list{
			margin: 0 0 0 -40px;
			@include flex();
			justify-content: flex-start;
			@media screen and (max-width: $phone){
				margin: 0 0 0 -15px;	
			}
			li{
				width: 25%;
				padding: 0 0 0 40px;
                margin: 40px 0 0;
				@media screen and (max-width: $phone){
					width: 50%;
					padding: 0 0 0 15px;
					margin: 15px 0 0;
				}
				&:nth-child(-n+4){
					margin-top: 0;
					@media screen and (max-width: $phone){
						margin-top: 15px;
					}
				}
				@media screen and (max-width: $phone){
					&:nth-child(-n+2){
						margin-top: 0;
					}
				}
				a{
					display: block;
					color: #FFF;
				}
				figure{
					width: 100%;
					padding: 90% 0 0;
					margin: 0 auto;
					position: relative;
					overflow: hidden;
					img{
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						object-position: center center;
						object-fit: contain;
					}
				}
				dl{
					margin: 20px 0 0;
					text-align: center;
					@media screen and (max-width: $phone){
						margin: 15px 0 0;	
					}
					dt{
						font-weight: 700;
						margin: 0 0 2px;
					}
					dd{
						font-size: 13px;
					}
				}
			}
		}
	}
	
	.detailbox{
		@include flex();
		align-items: center;
		margin: 0 0 64px;
		@media screen and (max-width: $phone){
			margin: 0 0 32px;
			display: block;
		}
		&__pic{
			width: 400px;
			border: rgba(#FFF,.2) 1px solid;
			padding: 32px;
			@media screen and (max-width: $phone){
				width: auto;
				padding: 15px;
			}
			img{
				width: 100%;
			}
		}
		&__txt{
			width: calc(100% - 400px);
			padding: 0 0 0 56px;
			@media screen and (max-width: $phone){
				width: auto;
				padding: 20px 0 0;
			}
			h2{
				@include min();
				font-size: 32px;
				font-weight: 700;
				@media screen and (max-width: $phone){
					font-size: 20px;	
				}
			}
			dl{
				@media screen and (max-width: $phone){
					margin: 4px 0 0;	
				}
				dt{
					font-size: 16px;
					@media screen and (max-width: $phone){
						font-size: 14px;
					}
					strong{
						line-height: 1;
						font-size: 24px;
						color: $yellow;
						@media screen and (max-width: $phone){
							font-size: 20px;	
						}
					}
				}
				dd{
					margin: 15px 0 0;
					padding: 15px 0 0;
					border-top: rgba(#FFF,.2) 1px solid;
				}
			}
		}
	}
	.btnarea-mt{
		margin-top: 48px;
		@media screen and (max-width: $phone){
			margin-top: 32px;	
		}
	}
	.pricelist{
		@include flex();
		border: rgba(#FFF,.12) 1px solid;
		@media screen and (max-width: $tab){
			display: block;	
		}
		dl{
			width: 50%;
			@include flex();
			border-top: rgba(#FFF,.12) 1px solid;
			margin: 1px 0 0;
			@media screen and (min-width: $tab + 1px){
				&:nth-child(2n+2){
					border-left: rgba(#FFF,.12) 1px solid;
				}
				&:nth-child(-n+2){
					border-top: none;
					margin-top: 0;
				}
			}
			@media screen and (max-width: $tab){
				width: 100%;
				&:first-of-type{
					border-top: none;
					margin-top: 0;
				}
			}
			dt,dd{
				padding: 15px;
				font-size: 14px;
				@media screen and (max-width: $phone){
					padding: 12px 15px;
					font-size: 13.5px;
				}
			}
			dt{
				width: 250px;
				background: rgba(#FFF,.12);
				text-align: left;
				@media screen and (max-width: $phone){
					width: 180px;
				}
			}
			dd{
				width: calc(100% - 250px);
				text-align: right;
				@media screen and (max-width: $phone){
					width: calc(100% - 180px);
				}
			}
		}
	}
}