.site-media{
	.medialist{
		margin: 0 0 64px;
		@media screen and (max-width: $phone){
			margin: 0 0 32px;	
		}
		&:last-of-type{
			margin: 0;
		}
		> li{
			list-style: none;
			background: url("../../../images/index/fv_txture.jpg") no-repeat;
			background-size: cover;
			border: rgba(#FFF,.12) 1px solid;
			padding: 24px;
			margin: 0 0 24px;
			@media screen and (max-width: $phone){
				padding: 15px;
				margin: 0 0 15px;
			}
			&:last-child{
				margin: 0;
			}
		}
		&__list{
			@include flex();
			justify-content: flex-start;
			margin: 24px 0 0;
			@media screen and (max-width: $phone){
				justify-content: space-between;	
			}
			> li{
				width: 23.5%;
				margin: 2% 2% 0 0;
				img{
					width: 100%;
				}
				@media screen and (min-width: $phone + 1px){
					&:nth-child(-n+4){
						margin-top: 0;
					}
					&:nth-child(n+4){
						margin-right: 0;
					}
				}
				@media screen and (max-width: $phone){
					width: calc(50% - 7.5px);	
					margin: 15px 0 0;
					&:nth-child(-n+2){
						margin-top: 0;
					}
				}
			}
		}
        dl{
            dt{
				color: $red;
				font-size: 20px;
				@media screen and (max-width: $phone){
					font-size: 18px;
					margin: 0 0 6px;
				}
				time{
					background: $red;
					color: #FFF;
					padding: 2px 12px;
					font-size: 13px;
					display: inline-block;
					margin: 0 8px 0 0;
					@media screen and (max-width: $phone){
						font-size: 12px;
						padding: 1px 15px;
					}
				}
            }
            dd{
				text-align: justify;
            }
        }
	}
}