.site-index{
	.news{
        position: relative;
		border-top: $red 8px solid;
        z-index: 2;
		background: #000 url("../../../images/index/fv_txture.jpg") center;
		background-size: cover;
		color: #FFF;
		.display{
			&.flex{
				@include flex();
				@media screen and (max-width: $phone){
					display: block;	
				}
			}
		}
		.bnrlist{
			@include flex();
			justify-content: center;
			margin: 32px 0 88px -24px;
			@media screen and (max-width: $phone){
				margin: 0 0 48px;
				display: block;
			}
			&:nth-child(-n+3){
				margin-top: 0;
			}
			li{
				width: 33.3%;
				padding: 0 0 0 24px;
				@media screen and (max-width: $phone){
					width: auto;
					padding: 0;
					margin: 0 0 16px;
					&:last-child{
						margin: 0;
					}
				}
				a{
					display: block;
					img{
						width: 100%;
					}
				}
			}
		}
		.newsbox{
			width: 100%;
			@include flex();
			@media screen and (max-width: $phone){
				display: block;
			}
		}
        .leftside{
            width: 280px;
			@media screen and (max-width: $phone){
				width: auto;
				position: relative;
			}
			h3{
				line-height: 1;
				font-size: 14px;
				margin: 0 0 16px;
				font-weight: 700;
				&:before{
					content: attr(data-eng);
					font-size: 36px;
					display: inline-block;
					margin: 0 24px 0 0;
					@media screen and (max-width: $phone){
						font-size: 20px;
						margin: 0 12px 0 0;
					}
				}
			}
			a{
				display: inline-block;
				font-weight: 700;
				color: #FFF;
				font-size: 15px;
				@media screen and (max-width: $phone){
					position: absolute;
					top: 0;
					right: 0;
				}
				&:hover{
					text-decoration: underline;
				}
				i{
					display: inline-block;
					font-size: 13px;
					margin: 0 0 0 12px;
				}
			}
        }
        ol{
            width: calc(100% - 280px);
            height: 125px;
            padding: 0 40px 0 0;
            overflow: auto;
			@media screen and (max-width: $phone){
				width: auto;
				padding: 15px;
				background: rgba(#FFF,.08);
				border-radius: 4px;
			}
            li{
                border-bottom: rgba(#FFF,.16) 1px solid;
                padding: 0 0 24px;
                margin: 0 0 24px;
				@media screen and (max-width: $phone){
					padding: 0 0 15px;
					margin: 0 0 15px;
				}
                &:last-child{
                    margin: 0;
                }
                a{
                    @include flex();
                    align-items: center;
                    color: #FFF;
					@media screen and (max-width: $tab){
						display: block;	
					}
                    &:hover{
                        h4{
                            text-decoration: underline;
                        }
                    }
                }
                span.time{
                    width: 14%;
                    font-size: 12px;
                    background: rgba(#FFF,.12);
                    color: #FFF;
                    padding: 2px 0;
                    text-align: center;
					@media screen and (max-width: $tab){
						width: 100px;
						display: inline-block;
					}
                }
                h4{
                    width: 86%;
                    font-size: 16px;
                    padding: 0 0 0 32px;
					@media screen and (max-width: $tab){
						width: auto;
						padding: 6px 0 0;
					}
                }
            }
        }
    }

	.firstcontent{
		background: #000;
		position: relative;
		&__h{
			position: absolute;
			top: 96px;
			left: 50%;
			@include min();
			font-size: 40px;
			padding: 24px 10px;
			letter-spacing: .48em;
			font-weight: 600;
			z-index: 2;
			@media screen and (min-width: $phone + 1px){
				-webkit-writing-mode: vertical-rl;
				-moz-writing-mode: vertical-rl;
				-ms-writing-mode: tb-rl;
				writing-mode: vertical-rl;
				white-space: nowrap;
				transform: translateX(-50%);
			}
			@media screen and (max-width: $tab){
				font-size: 24px;	
			}
			@media screen and (max-width: $phone){
				position: relative;
				top: 0;
				left: 0;
				font-size: 20px;
				text-align: center;
				margin: 0 0 12px;
				letter-spacing: 0em;
				padding: 10px 0 0;
				border-top: rgba(#FFF,.12) 1px solid;
			}
		}
		&__menu{
			@include flex();
			@media screen and (max-width: $phone){
				display: block;	
			}
			li{
				width: 50%;
				padding: 64px;
				@include flex();
				align-items: center;
				justify-content: center;
				position: relative;
				@media screen and (max-width: $tab){
					padding: 40px 30px;	
				}
				@media screen and (max-width: $phone){
					width: auto;
					padding: 40px 15px;
				}
				&:before{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: url("../../../images/index/twos_b_bg.png") no-repeat right top;
					background-size: 460px;
					opacity: .16;
					@media screen and (max-width: $phone){
						background-size: 230px;
					}
				}
				&:last-child{
					&:before{
						background: url("../../../images/index/twos_bg.png") no-repeat right top;
						background-size: 460px;
						opacity: .24;
						@media screen and (max-width: $phone){
							background-size: 230px;
						}
					}
				}
				> *{
					z-index: 2;
				}
				figure{
					max-width: 280px;
					width: 80%;
					margin: 0 auto 20px;
					@media screen and (max-width: $tab){
						width: 72%;	
					}
					img{
						width: 100%;
					}
				}
				dl{
					margin: 0;
					position: relative;
					z-index: 2;
                    width: 100%;
					text-align: center;
					dt{
						@include min();
						font-size: 32px;
						font-weight: 700;
						margin: 0 0 8px;
						@media screen and (max-width: $tab){
							font-size: 24px;	
						}
					}
					dd{
						font-size: 17px;
						width: 100%;
						min-height: 3.3em;
						max-width: 480px;
						margin: 0 auto;
						@media screen and (max-width: $tab){
							font-size: 13px;
							min-height: 1px;
						}
						&.btnarea{
							margin-top: 24px;
						}
					}
				}
			}
		}
		#indexswipe{
			.swiper-wrapper{
				transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
			}
			padding: 48px 0;
			@media screen and (max-width: $phone){
				padding: 32px 0;	
			}
			.swiper-slide{
				width: 280px;
				@media screen and (max-width: $phone){
					width: 140px;	
				}
				img{
					width: 100%;
				}
			}
		}
	}
	
	.secondcontent{
		background: #000 url("../../../images/index/fv_txture.jpg") center;
		background-size: cover;
		border-top: rgba($normal,.06) 12px solid;
		position: relative;
		overflow: hidden;
		&:before{
			content: 'MEDIA';
			font-size: 280px;
			font-weight: 700;
			line-height: 1;
			position: absolute;
			left: 2.5%;
			bottom: 20px;
			color: #FFF;
			opacity: .06;
			z-index: 1;
		}
		.videobox{
			@include flex();
			padding: 0 6%;
			max-width: 1800px;
			margin: 0 auto;
			position: relative;
			@media screen and (max-width: $phone){
				padding: 0 15px;
				display: block;
			}
			z-index: 2;
			&__h{
				width: 120px;
				@include min();
				color: #FFF;
				font-size: 36px;
				@media screen and (min-width: $phone + 1px){
					-webkit-writing-mode: vertical-rl;
					-moz-writing-mode: vertical-rl;
					-ms-writing-mode: tb-rl;
					writing-mode: vertical-rl;
					white-space: nowrap;
					@include flex();
					justify-content: flex-start;
					align-items: flex-end;
				}
				@media screen and (max-width: $tab){
					font-size: 24px;	
				}
				@media screen and (max-width: $phone){
					width: auto;
					font-size: 24px;
					text-align: center;
					margin: 0 0 8px;
				}
			}
			&__list{
				width: calc(100% - 120px);
				@include flex();
				@media screen and (max-width: $phone){
					width: auto;
					display: block;
				}
				li{
					width: calc(50% - 20px);
					@media screen and (max-width: $phone){
						width: auto;
						margin: 0 0 15px;
						&:last-child{
							margin: 0;
						}
					}
					iframe{
						width: 100%;
						height: 24vw;
						vertical-align: bottom;
						@media screen and (max-width: $tab){
							height: 18vh;	
						}
						@media screen and (max-width: $phone){
							height: calc(100vw / 16 * 9);	
						}
					}
					.caption{
						background: #000;
						text-align: center;
						padding: 10px 15px;
						font-size: 12px;
					}
				}
			}
		}
		.more{
			position: absolute;
			left: 6%;
			bottom: 66px;
			background: $d-red;
			padding: 12px 32px;
			color: $normal;
			z-index: 3;
			@media screen and (max-width: $tab){
				position: relative;
				left: 0;
				bottom: 0;
				margin: 24px 30px 0;
				display: block;
				text-align: center;
				border-radius: 4px;
			}
		}
	}
	
	.thirdcontent{
		background: #000 url("../../../images/index/recruit_bg.jpg") center fixed;
		background-size: cover;
		position: relative;
		@include flex();
		align-items: center;
		justify-content: center;
		@media screen and (max-width: $phone){
			background: #000 url("../../../images/index/recruit_bg.jpg") center;
			background-size: cover;
		}
		&:before{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: #000;
			opacity: .4;
		}
		dl{
			padding: 24px 0;
			text-align: center;
			@media screen and (max-width: $phone){
				padding: 24px 15px;	
			}
			dt{
				@include min();
				font-size: 32px;
				line-height: 1;
				&:before{
					content: 'RECRUIT';
					display: block;
					font-size: 12px;
					color: $red;
					margin: 0 0 16px;
					letter-spacing: .4em;
				}
				@media screen and (max-width: $phone){
					font-size: 24px;
					line-height: 1.56;
				}
			}
			dd.detail{
				margin: 24px 0;
				font-size: 17px;
				@media screen and (max-width: $phone){
					font-size: 13px;
					text-align: justify;
					br{
						display: none;
						
					}
				}
			}
		}
	}
	
	.fourcontent{
		background: #000 url("../../../images/index/fv_txture.jpg") center;
		background-size: cover;
		position: relative;
		overflow: hidden;
		&:before{
			content: 'SNS';
			font-size: 280px;
			font-weight: 700;
			line-height: 1;
			position: absolute;
			right: 2.5%;
			bottom: 20px;
			color: #FFF;
			opacity: .06;
			z-index: 1;
		}
		.flex{
			@include flex();
			z-index: 2;
			@media screen and (max-width: $phone){
				display: block;	
			}
		}
		.sns{
			width: calc(100% - 150px);
			@include flex();
			@media screen and (max-width: $phone){
				display: block;
				width: auto;
			}
			li{
				width: 31%;
				@media screen and (max-width: $tab){
					width: 32%;	
				}
				@media screen and (max-width: $phone){
					width: 100%;
					margin: 0 0 20px;
					&:last-child{
						margin: 0;
					}
				}
				h4{
					font-size: 16px;
					font-weight: 700;
					margin: 0 0 12px;
				}
			}
		}
		.other{
			width: 150px;
			padding: 0 0 0 40px;
			@media screen and (max-width: $phone){
				width: 100%;
				padding: 20px 0 0;
				@include flex();
				justify-content: flex-start;
			}
			li{
				margin: 0 0 8px;
				@media screen and (max-width: $phone){
					margin: 0 8px 0 0;	
				}
				&:last-child{
					margin: 0;
				}
			}
			.snsbtn{
				display: inline-block;
				padding: 3px 8px;
				background: $d-red;
				color: #FFF;
				font-size: 10px;
				border-radius: 4px;
				@media screen and (max-width: $phone){
					padding: 2px 8px;
					vertical-align: top;
				}
				i{
					display: inline-block;
					margin: 0 4px 0 0;
				}
			}
		}
	}
	
	.bnrarea{
		width: 100%;
		margin: 40px 0 0;
		text-align: center;
		@media screen and (max-width: $phone){
			margin: 24px 0 0;	
		}
		a{
			img{
				max-width: 100%;
			}
		}
	}
}