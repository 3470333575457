.site-second{
	padding: $pc-head + 8px 0 0;
	background-position: center;
	background-size: cover;
	position: relative;
	overflow: hidden;
	border-bottom: rgba(#FFF,.12) 1px solid;
	@media screen and (max-width: $tab){
		padding: $sp-head + 8px 0 0;
	}
	&:before{
		content: '';
		width: 100%;
		height: 100%;
		z-index: 1;
		background: url("../../../images/index/firstview.png") no-repeat center bottom -88px;
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		@media screen and (max-width: $tab){
			background: url("../../../images/index/firstview.png") no-repeat center bottom;
			background-size: cover;
		}
	}
	&:after{
		content: '';
		width: 100%;
		height: 80%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: .88;
        background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
        background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 );
		z-index: 2;
	}
	.title{
		position: relative;
		text-align: center;
        padding: 88px 0 120px;
		z-index: 4;
		@media screen and (max-width: $tab){
			padding: 32px 0 56px;	
		}
		@media screen and (max-width: $phone){
			padding: 24px 0;	
		}
		h1{
			@include min();
			font-size: 17px;
			line-height: 1;
			font-weight: 700;
			transform: translateY(-10px);
			letter-spacing: .12em;
			@media screen and (max-width: $tab){
				font-size: 15px;	
			}
			@media screen and (max-width: $phone){
				font-size: 14px;	
			}
			&:before{
				content: attr(data-eng);
				font-size: 48px;
				display: block;
				text-transform: uppercase;
				margin: 0 0 12px;
				letter-spacing: .08em;
				@media screen and (max-width: $tab){
					font-size: 40px;	
				}
				@media screen and (max-width: $phone){
					font-size: 28px;	
				}
			}
		}
	}
	.bread{
		position: relative;
		padding: 20px 0;
		background: #000;
		font-size: 12px;
		z-index: 4;
		width: 100%;
		ol{
			list-style: none;
			@include flex();
			justify-content: flex-start;
			li{
				list-style: none;
				a{
					color: #FFF;
				}
				&:after{
					content: "\f054";
					font-weight: 900;
					font-family: "Font Awesome 5 Free";
					display: inline-block;
					margin: 0 20px;
					font-size: 9px;
					transform: translateY(-1px);
					opacity: .88;
				}
				&:last-child{
					&:after{
						display: none;
					}
				}
				&:first-child{
					i{
						display: inline-block;
						margin: 0 8px 0 0;
						font-size: 12px;
						color: $red;
					}
				}
			}
		}
	}
	.fireps{
		bottom: -80%; 
		z-index: 1;
	}
}