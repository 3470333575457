.site-takeout{
	.tekeouttel{
		@include flex();
		align-items: center;
		justify-content: flex-start;
		margin: 0 0 24px;
		@media screen and (max-width: $tab){
			justify-content: center;
			margin: 0 0 32px;
		}
		@media screen and (max-width: $phone){
			margin: 0 0 24px;
			display: block;
		}
		&__btn{
			font-size: 15px;
			display: inline-block;
			padding: 12px 32px;
			background: $red;
			color: #FFF;
			margin: 0 12px 0 0;
			@media screen and (max-width: $phone){
				padding: 12px 16px;
				display: block;
				margin: 0 0 8px;
				font-size: 16px;
			}
			&:hover{
				background: #FFF;
				color: $red;
			}
			i{
				display: inline-block;
				transform: rotateY(180deg) translateY(-1px);
				margin: 0 8px 0 0;
			}
			&:last-of-type{
				margin: 0;
			}
			&__sml{
				font-size: 12px;
				@media screen and (max-width: $phone){
					font-size: 13px;
				}
			}
		}
		&__txt{
			margin: 0 0 0 24px;
			@media screen and (max-width: $tab){
				width: 100%;
				margin: 12px 0 0;
				text-align: center;
			}
		}
	}
	.takeoutlist{
		li{
			margin: 0 0 24px;
			&:last-child{
				margin: 0;
			}
			img{
				width: 100%;
			}
		}
	}
}