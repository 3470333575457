.site-privacy{
	.text{
		margin: 0 0 24px;
	}
	.site-module{
		.tablestyle{
			tbody{
				tr{
					th{

					}
					td{
						font-size: 13px;
						ol{
							margin: 24px 0 0;
							li{
								list-style: inside decimal;
								margin: 0 0 16px;
								padding: 0 0 16px;
								border-bottom: rgba(#FFF,.2) 1px dashed;
								&:last-child{
									margin: 0;
									padding: 0;
									border: none;
								}
							}
						}
					}
				}
			}
		}
	}
}