.site-woo{
	form.checkout_coupon{
		border: rgba(#FFF,.12) 4px solid;
		.form-row{
			margin: 8px 0 0;
		}
		.button{
			background: $red;
			color: #fff;
			&:hover{
				background: $d-red;
			}
		}
	}
    .selection{
        .select2-selection{
            @include font();
            font-size: 13px;
            color: #FFF;
            border: rgba(#FFF,.12) 1px solid;
            background: rgba(#FFF,.06);
            outline: none;
            padding: 0 8px;
            width: 100%;
            height: 38px;
            border-radius: 0;
            &__rendered{
                line-height: 38px;
            }
            &__arrow{
                height: 38px;
                right: 8px;
            }
        }
    }
	.woocommerce-billing-fields{
		h3{
			@include woo-h;
		}
		.woocommerce-input-wrapper{
			display: block;
			margin: 6px 0 0;
		}
	}
	.woocommerce-validated{
		input.input-text{
			border-color: $yellow !important;
		}
	}
	.woocommerce-checkout{
		.woocommerce-form{
			&__label{
				border: rgba(#FFF,.12) 1px solid;
				background: rgba(#FFF,.12);
                padding: 10px 20px;
                margin: 0 0 20px;
                @include flex();
				align-items: center;
				justify-content: flex-start;
                border-radius: 4px;
				cursor: pointer;
				input{
					margin: 0 8px 0 0;
				}
			}
		}
		#order_comments_field{
			.woocommerce-input-wrapper{
				display: block;
				margin: 6px 0 0;
			}
		}
	}
	#order_review_heading{
		@include woo-h;
		margin-top: 40px;
	}
}

.select2-dropdown{
    border: rgba(#FFF,.12) 1px solid;
    background: #000;
	.select2-search--dropdown{
		padding: 8px;
	}
    .select2-search__field{
        border: none !important;
        padding: 8px;
        background: rgba(#FFF,.12);
    }
	.select2-results__option[data-selected="true"]{
		background: $yellow;
	}
    .select2-results__option.select2-results__option--highlighted{
        background: $red;
        color: #FFF;
    }
}
