@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@600&display=swap');

@import "module/reset";
@import "module/module";


/* BASE COLOR
------------------------------------------------------------*/
$normal: #f5f4f4;
$red:    #e60012;
$d-red:  darken($red, 12%);
$orange: #e64100;
$yellow: #b79700;
$link:   $red;
$border: #ddd;


/* SITE SETTING
------------------------------------------------------------*/
$width:1120px;
$tab: $width - 1px;
$phone: 640px;

$pc-head:96px;
$sp-head:80px;
$ss-head:68px;


@media screen and (min-width: $width){
	@include default(
		$width, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		40px, //パディング
		#000 //ボディ背景
	);
}

@media screen and (max-width: $tab){
	@include default(
		auto, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
        30px, //パディング
		#000 //ボディ背景
	);
}

@media screen and (max-width: $phone){
	@include default(
		auto, //サイト幅
		12px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
        15px, //パディング
		#000 //ボディ背景
	);
}


@import "common/header";
@import "common/footer";
@import "common/bread";
@import "page/index";
@import "page/firstview";
@import "page/menu";
@import "page/about";
@import "page/media";
@import "page/recruit";
@import "page/contact";
@import "page/privacy";
@import "page/news";
@import "page/rule";
@import "page/takeout";
@import "page/guide";
@import "woo/common";
@import "woo/shop";
@import "woo/cart";
@import "woo/pay";
@import "woo/account";

.site-clum{
    @include flex();
    @media screen and (max-width:$tab){
        width: auto;
        display: block;
    }
    &__sidebar{
        width: 280px;
		.btnarea{
			margin-top: 24px;
		}
        @media screen and (max-width:$tab){
            width: auto;
        }
        @media screen and (max-width:$phone){
            margin: 32px 0 0;   
        }
    }
    &__container{
        width: calc(100% - 280px);
        padding: 0 48px 0 0;
        @media screen and (max-width:$tab){
            width: auto;
            padding: 0;
        }
        .pager{
            margin: 24px 0 0;
            text-align: center;
            a,span{
                $size: 28px;
                width: $size;
                height: $size;
                line-height: $size - 2px;
                text-align: center;
                display: inline-block;
                margin: 0 3px;
                border: #333 1px solid;
                background: #333;
                color: $normal;
                font-size: 13px;
            }
            span{
                background: $red;
                border-color: $red;
                color: #FFF;
            }
            a{
                &:hover{
                    color: $red;
                    background: none;
                    border-color: $red;
                }
            }
        }
    }
}


.site-module{
	&.wrap{
		padding: 96px 0;
		@media screen and (max-width: $tab){
			padding: 64px 0;	
		}
		@media screen and (max-width: $phone){
			padding: 32px 0;	
		}
		&.mid{
			padding: 72px 0;
			@media screen and (max-width: $tab){
				padding: 56px 0;	
			}
			@media screen and (max-width: $phone){
				padding: 32px 0;	
			}
		}
		&.no-top{
			padding-top: 0;
		}
		&.no-bottom{
			padding-bottom: 0;
		}
	}
	&.bg-red,
	.bg-red{
		background: $red;
	}
	&.bg-d-red,
	.bg-d-red{
		background: $d-red;
	}
	.col-red{
		color: $red;
	}
	.col-d-red{
		color: $d-red;
	}
	h2.large{
		@include min();
		text-align: center;
		font-size: 32px;
		line-height: 1;
		margin: 0 0 32px;
		letter-spacing: .12em;
		&:after{
			content: attr(data-eng);
			@include font();
			font-size: 13px;
			letter-spacing: .12em;
			display: block;
			margin: 14px 0 0;
		}
		@media screen and (max-width: $phone){
			font-size: 24px;	
		}
	}
    h2.borders,
    h3.borders,
    h4.borders{
        border-bottom: rgba(#FFF,.2) 1px solid;
		position: relative;
        padding: 12px;
		padding-left: 20px;
        font-size: 22px;
		line-height: 1;
        font-weight: 700;
		margin: 0 0 16px;
		@media screen and (max-width: $phone){
			font-size: 18px;	
		}
		small{
			display: inline-block;
			margin: 0 0 0 20px;
			font-size: 13px;
		}
		&:after{
			content: '';
			width: 5px;
			height: 70%;
			background: $red;
			border-radius: 4px;
			display: block;
			position: absolute;
			top: 15%;
			left: 0;
		}
    }

	.btnarea{
		text-align: center;
		a{
			display: inline-block;
			color: #FFF;
			font-size: 17px;
			border: #FFF 1px solid;
			padding: 10px 48px;
			min-width: 300px;
			position: relative;
			@media screen and (max-width: $phone){
				min-width: 1px;
				display: block;
				padding: 12px 30px;
			}
			&:hover{
				background: $red;
				border-color: $red;
				color: #FFF;
			}
            &.fill{
                border-color: $red;
                background: $red;
				&:hover{
					border-color: #FFF;
					background: none;
					color: #FFF;
				}
            }
			&:before{
				content: "\f061";
				font-weight: 900;
				font-family: "Font Awesome 5 Free";
				position: absolute;
				top: 50%;
				right: 20px;
				font-size: 11px;
				transform: translateY(-50%);
			}

		}
	}
	.tablestyle{
		width: 100%;
		border-collapse: collapse;
		border: rgba(#FFF,.12) 1px solid;
		@media screen and (max-width: $phone){
			display: block;	
		}
		&.formstyle{
			border: none;
			tbody{
				tr{
					@media screen and (max-width: $phone){
						padding: 0 0 8px;
						border: none;
						&:last-child{
							padding: 0;
						}
					}
					@media screen and (max-width: $phone){
						th,td{
							padding-left: 0;
							padding-right: 0;
						}	
					}
					th{
						background: none;
						width: 300px;
						@media screen and (max-width: $phone){
							width: auto;	
						}
						&.hiss{
							&:after{
								content: '必須';
								color: #FFF;
								font-size: 12px;
								background: $red;
								padding: 3px 20px;
								float: right;
								@media screen and (max-width: $phone){
									border-radius: 3px;	
									padding: 3px 15px;
								}
							}
						}
					}
					td{
						input[type=text],
						input[type=email],
						input[type=tel],
						input[type=password],
						textarea{
							@include font();
							font-size: 15px;
							color: #FFF;
							border: rgba(#FFF,.12) 1px solid;
							background: rgba(#FFF,.06);
							outline: none;
							padding: 6px 15px;
							width: 100%;
						}
						textarea{
							height: 200px;
						}
					}
				}
			}
		}
		tbody{
			@media screen and (max-width: $phone){
				display: block;	
			}
			tr{
				border-bottom: rgba(#FFF,.12) 1px solid;
				@media screen and (max-width: $phone){
					display: block;
					&:last-child{
						border-bottom: none;
					}
				}
				th,td{
					padding: 20px;
					vertical-align: middle;
					font-size: 15px;
					@media screen and (max-width: $phone){
						padding: 10px 15px;
						display: block;
						font-size: 14px;
					}
				}
				th{
					background: rgba(#FFF,.08);
					width: 200px;
					&.large{
						width: 250px;
						text-align: left;
						@media screen and (max-width: $phone){
							width: auto;
						}
					}
					@media screen and (max-width: $phone){
						background: rgba(#FFF,.12);
						width: auto;
						text-align: left;
					}
				}
				td{
					dl{
						margin: 0 0 24px;
						dt{
							background: $d-red;
							font-size: 14px;
							padding: 2px 12px;
							display: inline-block;
							margin: 0 0 4px;
						}
						&:last-of-type{
							margin: 0;
						}
					}
				}
			}
		}
	}
}

.site-404{
	text-align: center;
	.btnarea{
		margin: 24px 0 0;
	}
}

.fireps {
    $fireColor: $red;
    $fireColorT: rgba($orange,0);
    $dur: 1s;
    $blur: 0.08em;
    $fireRad: 3em;
    $parts: 30;
    $partSize: 30vw;
    font-size: 56px;
    position: absolute;
    width: 140%;
    height: 120%;
    left: -20%;
    bottom: -30%;
    z-index: 1;
    &__particle {
        animation: rise $dur ease-in infinite;
        background-image: radial-gradient($orange 10%,$red 20%,$fireColorT 70%);
        border-radius: 50%;
        mix-blend-mode: screen;
        opacity: 0;
        position: absolute;
        bottom: 0;
        width: $partSize;
        height: $partSize;
        @for $p from 1 through $parts {
            &:nth-of-type(#{$p}) {
                animation-delay: $dur * random();
                left: calc((100% - #{$partSize}) * #{($p - 1)/$parts});
            }
        }
    }
    @keyframes rise {
        from {
            opacity: 0;
            transform: translateY(0) scale(1);
        }
        25% {
            opacity: 1;
        }
        to {
            opacity: 0;
            transform: translateY(-15em) scale(0);
        }
    }
}

.pageup{
	position: fixed;
	right: 0;
	top: 50%;
	z-index: 1000;
	transform: translateX(100%);
	@include transition(200ms);
	@media screen and (max-width: $phone){
		display: none;
	}
	&.active{
		transform: translateX(0);
	}
	&__list{
		transform: translateY(-50%);
		li{
            white-space: nowrap;
            -webkit-writing-mode: vertical-rl;
            -moz-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
			a{
				display: block;
				@include min();
				letter-spacing: .16em;
				font-size: 14px;
				padding: 32px 8px;
				background: $d-red;
				color: #FFF;
				&:hover{
					opacity: .88;
				}
			}
			&:last-child{
				a{
					background: darken($d-red, 8%);
				}
			}
		}
	}
}

.fade-enter-active, .fade-leave-active {
	transition: 600ms;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}