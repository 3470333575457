@mixin woo-h(){
	border-bottom: rgba(#FFF,.2) 1px solid;
	position: relative;
	padding: 12px;
	padding-left: 20px;
	font-size: 22px;
	line-height: 1;
	font-weight: 700;
	margin: 0 0 16px;
	@media screen and (max-width: $phone){
		font-size: 18px;	
	}
	small{
		display: inline-block;
		margin: 0 0 0 20px;
		font-size: 13px;
	}
	&:after{
		content: '';
		width: 5px;
		height: 70%;
		background: $red;
		border-radius: 4px;
		display: block;
		position: absolute;
		top: 15%;
		left: 0;
	}
}

@mixin woo-input(){
	input[type=text],
	input[type=email],
	input[type=tel],
	input[type=password],
	input[type=number],
	select,
	textarea{
		@include font();
		font-size: 13px;
		color: #FFF;
		border: rgba(#FFF,.12) 1px solid;
		background: rgba(#FFF,.06);
		outline: none;
		padding: 8px;
		width: 100%;
	}
	option{
		background: #000;
	}
}

@mixin woo-notices{
	width: 100%;
	margin: 0 0 24px;
	font-size: 16px;
	color: #FFF;
	border-radius: 4px;
	padding: 10px 20px;
	background: $red;
	position: relative;
	text-align: left;
	overflow: hidden;
	border: none;
	@media screen and (max-width: $phone){
		font-size: 13px;
		padding: 8px 15px;
	}
	&:before{
		display: none;
	}
	a{
		@media screen and (min-width: $phone + 1px){
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			color: #FFF;
			padding: 0 20px;
			height: 100%;
			background: $d-red;
			@include flex();
			align-items: center;
			&:hover{
				text-decoration: underline;
			}
		}
		@media screen and (max-width: $phone){
			display: block;
			float: none;
			background: $d-red;
			color: #FFF;
			padding: 4px 15px;
			margin: 0 0 4px;
			border-radius: 4px;
		}
	}
}