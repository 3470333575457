.site-contact{
	.telbox{
		text-align: center;
		background: rgba(#FFF,.08);
		border-radius: 6px;
		margin: 0 0 32px;
		padding: 24px;
		dt{
			font-size: 16px;
			line-height: 1;
			margin: 0 0 10px;
		}
		dd{
			font-size: 32px;
			line-height: 1;
			font-weight: 700;
			i{
				display: inline-block;
				margin: 0 8px 0 0;
				transform: rotateY(-180deg);
				font-size: 30px;
				color: $red;
			}
			a{
				color: #FFF;
			}
		}
	}
	.send{
		text-align: center;
		margin: 32px 0 0;
		input{
			display: inline-block;
			color: #FFF;
			background: $red;
			border: none;
			font-size: 18px;
			width: 320px;
			padding: 10px 0;
			cursor: pointer;
			&:hover{
				background: $d-red;
			}
		}
	}
}