.site-about{
	.shops{
		&.mb{
			margin-bottom: 88px;
		}
		&__box{
			@include flex();
			margin: 0 0 40px;
			@media screen and (max-width: $phone){
				display: block;
				margin: 0 0 32px;
			}
			&__pic{
				width: 500px;
				@media screen and (max-width: $tab){
					width: 350px;	
				}
				@media screen and (max-width: $phone){
					width: auto;	
				}
				img{
					width: 100%;
				}
			}
			&__data{
				width: calc(100% - 500px);
				padding: 0 0 0 40px;
				@media screen and (max-width: $tab){
					width: calc(100% - 350px);
				}
				@media screen and (max-width: $phone){
					width: auto;
					padding: 0;
					margin: 32px 0 0;
				}
				dl{
					@include flex();
					align-items: center;
					margin: 0 0 8px;
					&.flstart{
						align-items: flex-start;
					}
					dt{
						width: 120px;
						text-align: center;
						background: rgba(#FFF,.08);
						@include flex();
						align-items: center;
						justify-content: center;
						padding: 8px 0;
					}
					dd{
						width: calc(100% - 120px);
						padding: 0 0 0 24px;
						.shdl{
							margin: 0 0 24px;
							&:last-of-type{
								margin-bottom: 0;
							}
							&__dt{
								min-width: 140px;
								font-size: 14px;
								background: $d-red;
								color: #FFF;
								text-align: center;
								padding: 4px 0;
								font-weight: 400;
								display: inline-block;
								@media screen and (max-width: $phone){
									font-size: 13px;
								}
							}
							&__dd{
								margin: 8px 0 0;
							}
						}
					}
				}
				&__map{
					margin: 24px 0 0;
					text-align: right;
					@media screen and (max-width: $phone){
						text-align: center;	
					}
					a{
						display: inline-block;
						border: $red 1px solid;
						color: $red;
						padding: 8px 24px;
						@media screen and (max-width: $phone){
							display: block;
							padding: 10px 0;
						}
						&:hover{
							color: #FFF;
							background: $red;
						}
						i{
							display: inline-block;
							margin: 0 8px 0 0;
						}
					}
				}
			}
		}
	}
}