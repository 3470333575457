#site-footer{
	background: #000;
	.gmap{
		@include flex();
		@media screen and (max-width: $phone){
			display: block;	
		}
		&__child{
			width: calc(50% - 1px);
			@media screen and (max-width: $phone){
				width: auto;
			}
			&__title{
				text-align: center;
				padding: 15px;
				background: #161616;
				position: relative;
				&:after{
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 16px 16px 0 16px;
					border-color: #161616 transparent transparent transparent;
					position: absolute;
					left: 50%;
					bottom: -16px;
					transform: translateX(-50%);
					z-index: 2;

				}
				@media screen and (max-width: $phone){
					padding: 8px 15px;	
				}
			}
		}
		iframe{
			width: 100%;
			height: 280px;
			filter: grayscale(1);
			vertical-align: bottom;
			@media screen and (max-width: $tab){
				height: 240px;	
			}
			@media screen and (max-width: $phone){
				height: 180px;	
			}
		}
	}
	.pagetop{
		background: rgba(#FFF,.08);
		display: block;
		text-align: center;
		padding: 15px 0;
		color: $normal;
		margin: 0 0 48px;
		@media screen and (max-width: $phone){
			padding: 10px;
			margin: 0 0 32px;
		}
	}
	.flex{
		@include flex();
		@media screen and (max-width: $tab){
			align-items: flex-start;
		}
		@media screen and (max-width: $phone){
			display: block;	
		}
	}
	.company{
		order: 2;
		width: 300px;
		@media screen and (max-width: $tab){
			width: 240px;
		}
		@media screen and (max-width: $phone){
			width: auto;	
		}
		dt{
			font-size: 18px;
			font-weight: 700;
			margin: 0 0 8px;
			padding: 0 0 8px;
			border-bottom: rgba($normal,.24) 1px solid;
		}
		dd{
			&.addr{
				font-size: 13px;
			}
			&.tel{
				font-size: 22px;
				font-weight: 700;
				color: $red;
				a{
					color: $normal;
				}
				i{
					display: inline-block;
					margin: 0 8px 0 0;
					transform: rotateY(180deg);
					color: $red;
				}
			}
		}
	}
	.fnav{
		@include flex();
		width: calc(100% - 300px);
		padding: 0 40px 0 0;
		order: 1;
		@media screen and (max-width: $tab){
			width: calc(100% - 240px);
		}
		@media screen and (max-width: $phone){
			width: auto;
			padding: 0;
			margin: 24px 0 0;
		}
		&__item{
			width: 50%;
            @include flex();
			@media screen and (max-width: $tab){
				display: block;	
			}
            dt{
                font-weight: 700;
                font-size: 16px;
				width: 100%;
				margin: 0 0 8px;
				@media screen and (max-width: $phone){
					font-size: 14px;	
				}
            }
            dd{
                width: 50%;
				@media screen and (max-width: $tab){
					width: auto;
				}
				@media screen and (max-width: $phone){
					font-size: 11px;	
				}
				a{
					color: $normal;
					opacity: .72;
					font-size: 12px;
				}
            }
		}
	}
	.copy{
		display: block;
		margin: 48px 0 32px;
		text-align: center;
		font-size: 10px;
		opacity: .72;
		@media screen and (max-width: $tab){
			margin: 0;
			padding: 32px 0;
		}
		@media screen and (max-width: $phone){
			font-size: 9px;
			text-align: left;
			margin: 0 15px;
		}
	}
}

.pageupschara{
	position: fixed;
	right: 0;
	bottom: 0;
	width: 120px;
	z-index: 100;
	opacity: 0;
	transform: translateY(24px);
	transition: all 400ms linear;
	transition-property: transform, opacity;
	&.active{
		opacity: 1;
		transform: translate(0,0);
	}
	@media screen and (max-width: $phone){
		width: 80px;
	}
	img{
		width: 100%;
	}
	&:before{
		content: 'ページ先頭へ';
		position: absolute;
		top: 0;
		left: -56px;
		background: $red;
		color: #FFF;
		font-size: 11px;
		border-radius: 999px;
		padding: 8px 16px;
		white-space: nowrap;
		z-index: -1;
		@media screen and (max-width: $phone){
			font-size: 10px;
			padding: 6px 12px;
			left: -56px;
		}
	}
	&:after{
		content: '';
		width: 24px;
		height: 16px;
		background: $red;
		position: absolute;
		top: 32px;
		left: -24px;
		clip-path: polygon(0 0, 100% 0, 100% 100%);
		@media screen and (max-width: $phone){
			width: 16px;
			height: 12px;
			top: 28px;
			left: -16px;
		}
	}
}