.site-index{
	.firstview{
		width: 100%;
		height: 100vh;
		min-height: 560px;
		max-height: 880px;
		position: relative;
		overflow: hidden;
		background: #000 url("../../../images/index/fv_txture.jpg") center;
		background-size: cover;
		@media screen and (max-width: $tab){
			max-height: 640px;
			min-height: 1px;
		}
		@media screen and (max-width: $phone){
			max-height: none;
			height: 480px;
		}
		&:before{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 20;
			pointer-events: none;
			box-shadow: inset 0 100px 100px rgba(#000,.48);
			@media screen and (max-width: $phone){
				display: none;
			}
		}
		&__bg{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: url("../../../images/index/firstview.png") no-repeat center bottom;
			background-size: cover;
			z-index: 3;
			@media screen and (max-width: $phone){
				background-size: auto 96%;
			}
		}
		&__title{
			width: 62%;
			max-width: 1024px;
			position: absolute;
			top: calc(50% - .5vh);
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			z-index: 10;
			@media screen and (max-width: $tab){
				width: 88%;	
			}
			@media screen and (max-width: $phone){
				top: 50%;
				transform: translateX(-50%) translateY(-50%);
			}
			img{
				width: 100%;
			}
		}
		&__copy{
			-webkit-writing-mode: vertical-rl;
			-moz-writing-mode: vertical-rl;
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
			white-space: nowrap;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			line-height: 1;
			font-size: 8px;
			letter-spacing: .2em;
			right: 36px;
			opacity: .96;
			z-index: 50;
			@media screen and (max-width: $tab){
				display: none;
			}
		}
		&__scroll{
			position: absolute;
			left: 50%;
			bottom: 32px;
			z-index: 100;
			transform: translateX(-50%);
			text-align: center;
			font-size: 10px;
			letter-spacing: .24em;
			@media screen and (max-width: $phone){
				display: none;
			}
			&:before{
				content: '';
				width: 30px;
				height: 52px;
				border: #FFF 1px solid;
				border-radius: 999px;
				position: absolute;
				bottom: 32px;
				left: 50%;
				transform: translateX(-50%);
			}
			&:after{
				content: '';
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background: #FFF;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: 64px;
				animation: sce 1600ms infinite;
				@keyframes sce{
					0%{
						bottom: 68px;
						opacity: 0;
					}
					20%{
						bottom: 68px;
						opacity: 1;
					}
					70%{
						bottom: 48px;
						opacity: 1;
					}
					100%{
						bottom: 48px;
						opacity: 0;
					}
				}
			}
		}

		&__bnr{
			position: absolute;
			right: 32px;
			bottom: 32px;
			width: 270px;
			z-index: 100;
			box-shadow: 0 0 32px rgba(#000,.2);
			@media screen and (max-width: $tab){
				width: 220px;
				right: 30px;
				bottom: 30px;
			}
			@media screen and (max-width: $phone){
				right: 15px;
				bottom: 15px;
				width: calc(50% - 15px);
			}
			img{
				width: 100%;
			}

		}
		&__canvas{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			opacity: .88;
			canvas{
				width: 100%;
			}
		}
	}

	.togara{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		&.over{
			z-index: 10;
			top: auto;
			bottom: -48vh;
		}
		&__item{
			width: 25vw;
			max-width: 417px;
			position: absolute;
			top: 16vh;
			z-index: 1;
			img{
				width: 100%;
			}
			&.l{
				left: -1vw;
			}
			&.r{
				right: -1vw;
			}
		}
	}
}