.site-recruit{
	.localnav{
		margin: 0 0 72px;
		@media screen and (max-width: $phone){
			 margin: 0 0 32px;	
		}
		ul{
			@include flex();
			@media screen and (max-width: $phone){
				display: block;	
			}
		}
		li{
			width: calc(50% - 15px);
			@media screen and (max-width: $phone){
				width: auto;
				margin: 0 0 20px;
			}
			a{
				display: block;
				border: rgba(#FFF,.16) 1px solid;
				transition: border-color 200ms;
				&:hover{
					border: #FFF 1px solid;
					img{
						opacity: 1;
					}
				}
				figure{
					position: relative;
					overflow: hidden;
					&:before{
						content: '';
						width: 64%;
						height: 100%;
						background: $d-red;
						position: absolute;
						top: 0;
						right: -15%;
						z-index: 2;
						transform: skew(-10deg);
						@media screen and (max-width: $phone){
							transform: skew(0deg);
							height: 101%;
							width: 50%;
							right: 0;
						}
					}
					img{
						width: 100%;
					}
					figcaption{
						position: absolute;
						top: 50%;
						left: 58%;
						transform: translateY(-50%);
						font-size: 24px;
						color: #FFF;
						z-index: 3;
						@media screen and (max-width: $phone){
							font-size: 17px;
							left: calc(50% + 15px);
						}
					}
				}
			}
		}
	}
	
	.recruitbox{
		margin: 0 0 64px;
		padding: 0 0 64px;
		border-bottom: rgba(#FFF,.16) 1px solid;
		&:last-of-type{
			margin: 0;
			padding: 0;
			border: none;
		}
		&__catch{
			text-align: center;
			margin: 0 0 24px;
			background: $d-red;
			padding: 15px;
			font-size: 17px;
			font-weight: 700;
			@media screen and (max-width: $phone){
				font-size: 14px;
				text-align: justify;
			}
		}
		&__txt{
			background: rgba(#FFF,.2);
			padding: 15px;
			font-size: 17px;
			text-align: center;
		}
		.mb{
			margin: 0 0 15px;
		}
		.mt{
			margin: 15px 0 0;
		}
	}
}