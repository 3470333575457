#site-header{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: $pc-head;
	@include flex();
	align-items: flex-end;
	justify-content: center;
	z-index: 1000;
	@media screen and (max-width: $tab){
		padding: 0 30px;
		height: $sp-head;
		justify-content: space-between;
		align-items: center;
	}
	@media screen and (max-width: $phone){
		position: fixed;
		padding: 0 15px;
		height: $ss-head;
		border-bottom: rgba(#FFF,.16) 1px solid;
		background: #000;
	}
	&.active{
		@media screen and (min-width: $tab + 1px){
			position: fixed;
			height: 88px;
			background: #000;
			animation: views 400ms forwards;
			border-bottom: $red 4px solid;
			@keyframes views{
				0%{
					transform: translateY(-100%);
				}
				100%{
					transform: translateY(0);
				}
			}
			.logo{
				width: 166px;
			}
			.contact{
				transform: translateY(-100%);
			}
			.cart{
				top: 0;
				transform: translateY(-100%);
			}
		}
	}
	.logo{
		width: 200px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		z-index: 3;
		@media screen and (max-width: $tab){
			width: 160px;
			position: relative;
			margin: 0 auto;
			top: 0;
			left: 0;
			transform: translateX(0) translateY(0);
		}
		@media screen and (max-width: $phone){
			width: 140px;
		}
		img{
			width: 100%;
		}
	}
	.btns{
		display: none;
		@media screen and (max-width: $tab){
			position: absolute;
			top: 20px;
			right: 30px;
			width: 38px;
			height: 38px;
			background: $red;
			color: #FFF;
			@include flex();
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			cursor: pointer;
			transition: box-shadow 400ms ease;
			box-shadow: 0 0 20px rgba($d-red,9%);
			&:hover{
				box-shadow: 0 0 0 8px rgba(#FFF,.16);
			}
			&.open{
				z-index: 100;
			}
			&.close{
				z-index: 1000;
			}
		}
		@media screen and (max-width: $phone){
			top: 14px;
			right: 15px;	
		}
	}
	.toggle{
		@media screen and (min-width: $tab + 1px){
			display: block !important;
		}
		&__inner{
			@media screen and (max-width: $tab){
				position: fixed;
				width: 100%;
				height: 100%;
				padding: 80px 0 0;
				top: 0;
				left: 0;
				z-index: 5000;
				background: #000;
				opacity: 0;
				transition: opacity 600ms;
				&.active{
					opacity: 1;
				}
			}
		}
	}
	.gnavi{
		transform: translateY(-28px);
		@media screen and (max-width: $tab){
			transform: translateY(0);
		}
		ul{
			@include flex();
			padding: 0 0 0 8px;
			@media screen and (max-width: $tab){
				padding: 0 30px;	
			}
			@media screen and (max-width: $phone){
				padding: 0 15px;
			}
			li{
				width: 130px;
				text-align: center;
				@media screen and (max-width: $tab){
					width: 32%;
					margin: 16px 0 0;
                    &:nth-child(-n+3){
                        margin-top: 0;
					}
				}
				@media screen and (max-width: $phone){
					width: auto;
					width: calc(50% - 7.5px);
					margin: 15px 0 0;
                    &:nth-child(-n+3){
                        margin-top: 15px !important;
					}
					&:nth-child(-n+2){
						margin-top: 0;
					}
				}
				&:nth-child(3){
					margin: 0 260px 0 0;
					@media screen and (max-width: $tab){
						margin: 0;	
					}
				}
				a{
					@include min();
					color: $normal;
					font-weight: 600;
					font-size: 15px;
					line-height: 1;
					display: block;
					position: relative;
					@media screen and (max-width: $tab){
						display: block;
						padding: 15px;
						border: rgba(#FFF,.24) 1px solid;
						border-radius: 4px;
						&:hover{
							background: rgba(#FFF,.24);
						}
					}
					&:before{
						content: attr(data-eng);
						display: block;
						font-size: 8px;
						color: $red;
						letter-spacing: .12em;
						margin: 0 0 4px;
						@media screen and (max-width: $tab){
							display: none;
						}
					}
					&:after{
						content: '';
						width: 0;
						height: 2px;
						background: $red;
						position: absolute;
						left: 50%;
						bottom: -12px;
						transform: translateX(-50%);
						transition: width 100ms;
						@media screen and (max-width: $tab){
							display: none;
						}
					}
					&:hover{
						&:after{
							width: 48px;
						}
					}
				}
			}
		}
	}
	.contact{
		position: absolute;
		top: 0;
		right: 72px;
		@include flex();
		@media screen and (max-width: $tab){
			position: relative;
			top: 0;
			right: auto;
			left: 0;
			margin: 32px 30px 0;
			justify-content: flex-start;
		}
		@media screen and (max-width: $phone){
			margin: 15px 15px 0;
			justify-content: space-between;
		}
		li{
			overflow: hidden;
			@media screen and (max-width: $tab){
				width: 32%;
				margin: 0 2% 0 0;
			}
			@media screen and (max-width: $phone){
				width: calc(50% - 7.5px);
				margin: 0;
			}
			&:first-child{
				border-radius: 0 0 0 4px;
				@media screen and (max-width: $tab){
					border-radius: 4px;	
				}
			}
			&:last-child{
				border-radius: 0 0 4px 0;
				@media screen and (max-width: $tab){
					border-radius: 4px;	
				}
				a{
					background: darken($red, 12%);
				}
			}
			a{
				background: $red;
				color: $normal;
				display: block;
				font-size: 12px;
				padding: 4px 20px;
				@media screen and (max-width: $tab){
					font-size: 14px;
					padding: 15px;
					line-height: 1;
					text-align: center;
				}
			}
		}
	}
    .cart{
        position: absolute;
        top: 4px;
        right: 32px;
		@media screen and (max-width: $tab){
			top: auto;
			bottom: 30px;
			right: 30px;
		}
		@media screen and (max-width: $phone){
			bottom: 15px;
			right: 15px;
		}
        a{
            font-size: 15px;
            color: $normal;
			@media screen and (max-width: $tab){
				width: 42px;
				height: 42px;
				border: rgba(#FFF,.2) 1px solid;
				border-radius: 50%;
				display: block;
				@include flex();
				align-items: center;
				justify-content: center;
			}
        }
    }
}